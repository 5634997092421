<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th v-for="field in tableFields" :key="field.attribute">
            <td v-if="fields_to_sort.some(i => field.attribute === i) && field.attribute !== 'testContact'"
                class="d-flex align-center justify-start"
                style="cursor: pointer"
                @click="$emit('sort', {field: field.attribute, direction: get_sorting_direction(field.attribute)})"
            >
              <span v-if="sort[field.attribute] === 'asc' || sort[field.attribute] === 'desc' " class="primary--text">
                {{ field.label }}
              </span>
              <span v-else>
                {{ field.label }}
              </span>
              <div v-if="fields_to_sort.some(i => field.attribute === i)" class="d-flex flex-column ml-3">
                <v-btn v-if="sort[field.attribute] === 'asc' || sort[field.attribute] === 'desc' " color="primary" small icon>
                  <v-icon v-if="sort[field.attribute] === 'asc'">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon v-if="sort[field.attribute] === 'desc'">
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </div>
            </td>
            <td
              v-else
              class="d-flex align-center justify-start"
            >
              <span>
                {{ field.label }}
              </span>
            </td>
          </th>
          <th />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr
          v-for="contact in contacts"
          :key="contact.id"
        >
          <td
            v-for="field in tableFields"
            :key="field.attribute"
            :class="{'search-table__id': field.attribute === 'id'}"
          >
            <span v-if="contact[field.attribute] === true">
              <v-icon size="20px" color="#1f9f43">mdi-alpha-t-circle</v-icon>
            </span>
            <span v-else-if="contact[field.attribute] === false" />
            <span v-else-if="field.attribute === 'id'">
              <v-menu :close-on-content-click="false" open-on-hover>
                <template #activator="{ on, attrs }">
                  <span class="id-number">{{ contact[field.attribute] }}</span>
                  <span class="id-icon">
                    <v-btn color="#2b84eb" icon small v-bind="attrs" v-on="on">
                      <v-icon color="#2b84eb" size="18">$eye</v-icon>
                    </v-btn>
                  </span>
                </template>

                <item-details-popup
                  :id="contact.id"
                  :key="contact.id"
                  :contact="contact"
                  :title="`${contact.firstName} ${contact.lastName}`"
                  @open-details="$router.push({name: 'ContactDetails', params: {id: contact.id}})"
                />
              </v-menu>
            </span>
            <span v-else-if="field.attribute === 'createdAt'" :title="getDateWithDayNameAndTime(contact.createdAt)">
              {{ getDate(contact.createdAt) }}
            </span>
            <span v-else style="white-space: nowrap;">
              {{ deep_find(contact, field.attribute) }}
            </span>
          </td>
          <td>
            <router-link :to="{name: 'ContactDetails', params: {id: contact.id}}">
              <span>
                <v-icon size="14">$right_arrow</v-icon>
              </span>
            </router-link>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import ItemDetailsPopup from "@/views/Contacts/components/ItemDetailsPopup";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "SearchResultsTable",
  components: { ItemDetailsPopup },
  mixins: [datesMixin],
  props: {
    contacts: {
      default: () => [],
      type: Array,
    },
    tableFields: {
      default: () => [],
      type: Array,
    },
    sort: {
      default: () => ({}),
    },
  },
  data() {
    return {
      fields_to_sort: [
        "firstName",
        "lastName",
        "createdAt"
      ],
    };
  },
  methods: {
    deep_find(obj, path) {
      let paths = path.split("."),
        current = obj,
        i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }

      if (path === 'createdAt') {
        current = this.getDate(current)
      }

      return current;
    },

    get_sorting_direction(field) {
      if (!this.sort[field]) {
        return "asc";
      }

      if (this.sort[field] === "asc") {
        return "desc";
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
</style>
